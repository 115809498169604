import {delRt, getRt, postHdById, postRt} from './base.js'

/**
 * 时间: 2024-07-01
 * 作者: 韩广志
 * 进修招聘
 */
//获取推荐
export function getRecommendApi (data) {
    return getRt('/user/educationRecruitRecommend/recommend/mima', data)
}

/**
 * 时间: 2024-07-02
 * 作者: 韩广志
 * 获取数据字典
 */
export function getHospitalDepartmentsApi () {
    return getRt('/user/hospitalDepartments/name')
}

//根据code查询数据字典
export function getShuJuZiDianApi (code) {
    return getRt('/user/educationRecruitDictionary/content/' + code)
}

//获取列表数据
export function getRecruitmentTableData (data) {
    return getRt('/user/educationRecruit/info/mima/type', data)
}

//获取单个数据
export function getRecruitmentOneApi (id) {
    return getRt('/user/educationRecruit/info/mima/' + id)
}

//添加收藏
export function getRecruitmentSHouCang (data) {
    return postRt('/user/userAction/collection', data)
}

//取消收藏
export function postRecruitmentQuXiaoSC (id) {
    return delRt('/user/userAction/collection/EDUCATION/' + id)
}

//获取城市
export function getRecruitmentChengShiApi (data) {
    return getRt('/user/educationRecruit/info/mima/city', data)
}

//热刺
export function getRecruitmentHotApi () {
    return getRt('/user/educationRecruitSearchRecords/searchRecords/hot')
}

//搜索历史
export function getRecruitmentHistoryApi (pageSize) {
    return getRt('/user/educationRecruitSearchRecords/searchRecords/history/' + pageSize)
}

//搜索
export function getRecruitmentSearchApi (data) {
    return getRt('/user/educationRecruit/info/mima/searchAdvise', data)
}

//根据条件进行搜索
export function getRecruitmentSearchOneApi (data) {
    return getRt('/user/educationRecruit/info/mima/search', data)
}

//查询是否订阅
export function getRecruitmentDingYueApi (data) {
    return getRt('/user/educationRecruitSubscribe/subscribe/detail/' + data)
}

//获取订阅列表
export function getRecruitmentDingYueListApi (data) {
    return getRt('/user/educationRecruitSubscribe/subscribe/list', data)
}

//订阅
export function postRecruitmentDingYueAddApi (data) {
    return postRt('/user/educationRecruitSubscribe/subscribe', data)
}

//获取收藏
export function getRecruitmentShouCangApi (data) {
    return getRt('/user/educationRecruitFollow/follow/list', data)
}

//添加买点
export function postRecruitmentMaiDianApi (data) {
    return postHdById('/v3/addReportContent', {}, data)
}